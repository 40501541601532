<template>
  <v-app class="lato-font">
    <div class="row marg-0 padd-t-86">
      <div class="col-md-7 col-sm-12 col-12 padd-0">
        <div class="">
          <div class="row marg-0 align-center">
            <div class="marg-0">
              <div>
                <span class="fsize24 marg-0 clr-000000"> 9884054321</span>
              </div>
              <span class="f-size16 clr-f74f73 padd-t-6 fw-bold"
                >Above the mobile number already registered !</span
              >
            </div>
          </div>
          <div class="marg-t-38">
            <span class="f-size16 fw-bold clr-000000"
              >Would you like to declare the Mobile belongs to</span
            >
          </div>
          <div class="marg-t-14">
            <span class="vl-middle">
              <input
                type="radio"
                id="number"
                name="mobile"
                value="num"
                checked
                class="h-18 w-18"
              />
            </span>
            <span class="fsize14 clr-000000 marg-l-8">Self</span>
          </div>
          <div class="marg-t-14">
            <span class="vl-middle">
              <input
                type="radio"
                id="number"
                name="mobile"
                value="num"
                checked
                class="h-18 w-18"
              />
            </span>
            <span class="fsize14 clr-000000 marg-l-8">Spouse</span>
          </div>
          <div class="marg-t-14">
            <span class="vl-middle">
              <input
                type="radio"
                id="number"
                name="mobile"
                value="num"
                checked
                class="h-18 w-18"
              />
            </span>
            <span class="fsize14 clr-000000 marg-l-8">Father</span>
          </div>
          <div class="marg-t-14">
            <span class="vl-middle">
              <input
                type="radio"
                id="number"
                name="mobile"
                value="num"
                checked
                class="h-18 w-18"
              />
            </span>
            <span class="fsize14 clr-000000 marg-l-8">Mother</span>
          </div>
        </div>
        <div class="marg-t-40 marg-b-90">
          <router-link
            to="/details"
            active-class="active"
            tag="button"
            exact
            class="btn-color fsize18 padd-0-16 bor-radius h-40"
            >Continue</router-link
          >
        </div>
      </div>
      <div class="col-md-5 col-sm-12 padd-0 hideimgmob">
        <img class="desktopimg" src="..\assets\Desktop-login.svg" alt="" />
      </div>
    </div>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
.padd-t-86 {
  padding-top: 86px;
}
.clrplce input::-webkit-input-placeholder {
  color: #a1a1a1 !important;
}
</style>